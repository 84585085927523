import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary">
      {'Copyright © '}
      <Link color="inherit" href="https://specularo.com/">
        Specularo
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function Footer({visible}) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: "100%",
        visibility: visible? "visible" : "hidden"
        /*position: "absolute",
        bottom: 0,
        right: 0,
        left: 0*/
      }}
    >


      <Box
        component="footer"
        sx={{
          py: 2,
          px: 2,
          mt: 'auto',
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[200]
              : theme.palette.grey[800],
        }}
      >
        <Container maxWidth="sm" sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          rowGap: "5px"
        }}>
          <Typography variant="body1">
            Created with 💙
          </Typography>

          <Copyright />
          <Typography variant="body1">
            <img src="https://specularo.com/dark.png" alt="" style={{ maxWidth: "100px", marginTop: "5px" }} />
          </Typography>
        </Container>
      </Box>
    </Box>
  );
}