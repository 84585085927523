export const rasterLayerOptions = {
    'id': 'parking_raster_layer',
    'source': 'parking_raster',
    'type': 'raster'
}

export const parkingAvailableLayerOptions = {
    'type': 'fill-extrusion',
    'minzoom': 15.0,
    'paint': {
        'fill-extrusion-color': "#55ff63",
        'fill-extrusion-opacity': 0.3,
        'fill-extrusion-height': 1,
        'fill-extrusion-base': 0
    },
    'filter': ["all", ["!=", "status", false]]
}
export const parkingTakenLayerOptions = {
    'type': 'fill-extrusion',
    'minzoom': 15.0,
    'paint': {
        'fill-extrusion-color': "#ff5555",
        'fill-extrusion-opacity': 0.3,
        'fill-extrusion-height': 1,
        'fill-extrusion-base': 0
    },
    'filter': ["all", ["!=", "status", true]]
}
export const parkingRouteStrokeLayerOptions = {
    'type': 'line',
    'minzoom': 15.0,
    'paint': {
        'line-color': "white",
        'line-width': 12,
        'line-opacity': 1.0
    },
    'layout': {
        'visibility': 'visible',
        'line-cap': 'round'
    }
}

export const parkingRouteLineLayerOptions = {
    'type': 'line',
    'minzoom': 15.0,
    'paint': {
        'line-color': "#FF5733",
        'line-width': 10,
        'line-opacity': 1.0
    },
    'layout': {
        'visibility': 'visible',
        'line-cap': 'round'
    }
}

export const indoorOuterWallsLayerOptions = {
    'type': 'fill-extrusion',
    'minzoom': 15.0,
    'paint': {
        'fill-extrusion-color': "#55ff63",
        'fill-extrusion-opacity': 0.3,
        'fill-extrusion-height': 5,
        'fill-extrusion-base': 0
    },
    'filter': ["all", ["==", "type", "wall"]]
}