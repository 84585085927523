import { useEffect } from "react"
import { useParams } from "react-router-dom";

export default function Shorten() {
    const { shortId } = useParams();
    useEffect(() => {
        fetch(`${process.env.REACT_APP_BACKEND_URL}/otg/shorten_link/${shortId}`, {
            method: "GET",
        })
            .then(res => res.json())
            .then(res => {
                console.log(res)
                window.location.replace(`${res.goto}`);
            })
            .catch(err => {
                console.error(err)
            })
    }, [])

    return (
        <div style={{display: "flex", alignItems: "center", justifyContent: "center", fontSize: "24px", width: "100vw", height: "100vh", textAlign: "center"}}>כבר מעבירים אתכם לשירות הנדרש 😉</div>
    )
}