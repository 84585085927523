import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SessionContext from "../components/SessionContext";


export default function EventPhotosPage() {
    const { serviceId, servicelisting } = useParams();
    const [contactInfo, setContactInfo] = useState({})
    const layoutContext = useContext(SessionContext)

    useEffect(() => {
        console.log(serviceId)
        console.log(servicelisting)

        fetch(`${process.env.REACT_APP_BACKEND_URL}/otg/photos`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                serviceId: serviceId
            })
        })
        .then(res => res.json())
        .then(res => {
            console.log(res)
            setContactInfo(res)
            if(res.guestApp) {
                layoutContext.setInfo({...layoutContext, guestApp: res.guestApp})
            }
        })
    }, [])

    const downloadImage = () => {
        const link = document.createElement('a');
        link.href = contactInfo.photos[0];
        link.download = 'מזכרת מהאירוע.jpg'; // Default name for the downloaded file
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

    return (
        <section>
            <h1 dir="rtl">{layoutContext.guestApp && layoutContext.guestApp.topText ? layoutContext.guestApp.topText : "תודה שהשתתפתם במסיבה, מקווים לראותכם שוב"}</h1>

            <div className="image-grid">
            {
                contactInfo?.response &&
                contactInfo.response.map((item, i) => (
                    item.photos && <a href={item.photos[0]} download={"Event Photo " + i + ".jpg"}>
                        <img key={i} src={item.photos[0]} alt={"Item " + i} /> 
                    </a>
                ))
            }
            </div>
        </section>
    )
}