import ParkingMap from "../components/ParkingMap";

export default function ParkingPage() {
    return (
        <section>
            <ParkingMap language="en" viewOptions={{
                latitude: 32.074615,
                longitude: 34.791594,
                zoom: 19.5,
                bearing: 0,
                pitch: 0
            }} />
        </section>
    )
}