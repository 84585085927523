import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import SessionContext from './SessionContext';

const pages = [];
const settings = [  ];

function ResponsiveAppBar({visible}) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <SessionContext.Consumer>
      {
        (remoteInfo) => (
          <AppBar position="static"  sx={{visibility: visible? "visible" : "hidden", boxShadow: remoteInfo.guestApp && remoteInfo.guestApp.boxShadow ? remoteInfo.guestApp.boxShadow : (remoteInfo.guestApp && remoteInfo.guestApp.backgroundColor && remoteInfo.guestApp.backgroundColor === "transparent") ? "none": "1px", backgroundColor: remoteInfo.guestApp && remoteInfo.guestApp.backgroundColor ? remoteInfo.guestApp.backgroundColor : "#5757ff"}}>
            <Container maxWidth="xl">
              <Toolbar disableGutters  sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                <img src={remoteInfo.guestApp && remoteInfo.guestApp.logo ? remoteInfo.guestApp.logo : `${remoteInfo?.branch?.company?.logo}`} alt="" style={{maxWidth: "130px"/*, filter: "drop-shadow(0px 0px 3px rgba(0,0,0,0.9))"*/}} />
                {
                  /*
                  <Typography
                    variant="h6"
                    noWrap
                    component="a"
                    href="/"
                    sx={{
                      mr: 2,
                      display: { xs: 'none', md: 'flex' },
                      fontFamily: 'monospace',
                      fontWeight: 700,
                      letterSpacing: '.3rem',
                      color: 'inherit',
                      textDecoration: 'none',
                    }}
                  >
                    Specularo
                  </Typography>
                  */
                }
                
                {
                  /*
<Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                    color="inherit"
                  >
                    <MenuIcon />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{
                      display: { xs: 'block', md: 'none' },
                    }}
                  >
                    {pages.map((page) => (
                      <MenuItem key={page} onClick={handleCloseNavMenu}>
                        <Typography textAlign="center">{page}</Typography>
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
                
                  */
                }

              </Toolbar>
            </Container>
          </AppBar>
        )
      }
    </SessionContext.Consumer>
  );
}
export default ResponsiveAppBar;